import { Box, CircularProgress } from '@mui/material';
import { navigate } from 'gatsby';

import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showFlashMessage } from 'modules/ui/slice';

import { selectEmailVerified, selectProfileLoaded } from '../selectors';
import { MESSAGES } from '../constants';

type EmailVerifiedRequiredGuardProps = {
  children: ReactNode;
};

const EmailVerifiedRequiredGuard = ({
  children,
}: EmailVerifiedRequiredGuardProps) => {
  const dispatch = useDispatch();
  const emailVerified = useSelector(selectEmailVerified);
  const isProfileLoaded = useSelector(selectProfileLoaded);

  if (!isProfileLoaded) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <CircularProgress></CircularProgress>
      </Box>
    );
  }

  if (!emailVerified) {
    navigate(-1);
    dispatch(
      showFlashMessage({
        type: 'error',
        message: MESSAGES.LOGIN_WITHOUT_EMAIL_VERIFICATION,
      })
    );
    return null;
  }

  return <>{children}</>;
};

export default EmailVerifiedRequiredGuard;
