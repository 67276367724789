/* eslint-disable max-lines */
import { courses as types } from '@codesass/types';
import {
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Button,
  IconButton,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
  Link as MuiLink,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { AccountBalance, Delete, Receipt } from '@mui/icons-material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { courses as api } from '@codesass/api';
import { Link, navigate } from 'gatsby';

import { useSelector } from 'react-redux';

import { StaticImage } from 'gatsby-plugin-image';

import { selectIsLoggedIn, selectProfileLoaded } from 'modules/auth/selectors';

import ProgressIndicator from 'modules/ui/components/shared/ProgressIndicator';

import fbTrack from 'modules/shared/helpers/fbTrack';

import {
  UPLOAD_SLIP_SUPPORTED_FORMATS,
  UPLOAD_SLIP_MAX_FILE_SIZE,
} from '../constants';
import { getCoursePath } from '../helpers/paths';

type RegisterProps = Pick<
  types.Course,
  'slug' | 'title' | 'price' | 'promotion' | 'terms'
>;

const bookBankInfo = process.env['GATSBY_BOOK_BANK']
  ?.split('\n')
  .map((l, i) => <div key={i}>{l}</div>);

const Register = ({ slug, title, price, promotion, terms }: RegisterProps) => {
  const uploadSlipRef = useRef<HTMLInputElement | null>(null);
  const [uploadFile, setUploadFile] = useState<File>();
  const [uploadFileError, setUploadFileError] = useState<string>();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [registered, setRegistered] = useState(false);
  const isProfileLoaded = useSelector(selectProfileLoaded);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isRegistrationLoaded, setIsRegistrationLoaded] = useState(false);
  const [isUnapprovedStatus, setIsUnapproveStatus] = useState(false);

  const navigateToCoursePage = useCallback(() => {
    navigate(getCoursePath(slug), { replace: true });
  }, [slug]);

  const loadRegistration = useCallback(async () => {
    const registration = await api.getRegistration(slug);

    setIsRegistrationLoaded(true);

    if (registration === undefined) return;
    if (!('approved' in registration)) return;

    if (registration?.approved) {
      return navigateToCoursePage();
    }

    setIsUnapproveStatus(true);
  }, [navigateToCoursePage, slug]);

  const openFileDialog = useCallback(() => uploadSlipRef.current?.click(), []);

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (!file) return;
      if (file.size > UPLOAD_SLIP_MAX_FILE_SIZE) {
        event.target.value = '';
        setUploadFileError(
          `ขนาดไฟล์ต้องไม่เกิน ${UPLOAD_SLIP_MAX_FILE_SIZE / 1_024 / 1_024} MB`
        );

        return;
      }

      setUploadFile(file);
      event.target.value = '';
    },
    []
  );

  const clearUploadFileError = useCallback(() => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setUploadFileError(undefined);
  }, []);

  const deleteFileUpload = useCallback(() => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setUploadFile(undefined);
  }, []);

  const handleAcceptTerms = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAcceptTerms(event.target.checked);
    },
    []
  );

  const register = useCallback(async () => {
    if (!acceptTerms || !uploadFile) return;

    fbTrack('track', 'Purchase', {
      content_type: 'course',
      content_ids: [slug],
      content_name: title,
      value: price,
      currency: 'THB',
    });
    await api.register({ slug, title, price, promotion, slip: uploadFile });
    setRegistered(true);
  }, [acceptTerms, price, promotion, slug, title, uploadFile]);

  useEffect(() => {
    if (isProfileLoaded && !isLoggedIn) {
      navigateToCoursePage();
    }

    loadRegistration();
  }, [
    isLoggedIn,
    isProfileLoaded,
    loadRegistration,
    navigateToCoursePage,
    slug,
  ]);

  if (!isProfileLoaded || !isRegistrationLoaded) {
    return <ProgressIndicator></ProgressIndicator>;
  }

  if (isUnapprovedStatus) {
    return (
      <Stack alignItems="center">
        <Box maxWidth={350} my={2}>
          <StaticImage
            src="../../../assets/images/lesson-permission-denied.png"
            alt="Creator"
          />
        </Box>
        <Typography variant="h5" my={2}>
          คอร์สของคุณกำลังรอการอนุมัติ
        </Typography>
        <Button variant="contained" component={Link} to={getCoursePath(slug)}>
          กลับสู่คอร์ส
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Container maxWidth="md" sx={{ my: 2 }}>
        <Typography variant="h4" component="h1" align="center">
          ลงทะเบียน
        </Typography>
        <Typography
          component="h2"
          align="center"
          fontSize={28}
          sx={{ my: 2, color: grey[800] }}
        >
          คอร์ส {title}
        </Typography>
        <Card>
          <CardHeader
            title="ช่องทางชำระเงิน"
            avatar={
              <Avatar>
                <AccountBalance></AccountBalance>
              </Avatar>
            }
          ></CardHeader>
          <CardContent sx={{ bgcolor: theme => theme.palette.secondary.main }}>
            {bookBankInfo}
          </CardContent>
        </Card>
        <Typography sx={{ my: 2 }}>
          โปรดชำระเงินจำนวน{' '}
          <Typography component="span" fontWeight="bold">
            {(promotion ?? price).toLocaleString()} บาท
          </Typography>{' '}
          ผ่านการโอนเข้าบัญชีข้างบนเพื่อเป็นการลงทะเบียน <br />
          ภายหลังการชำระเงินโปรดแนบหลักฐานการชำระเงินผ่านปุ่ม{' '}
          <Typography component="span" fontWeight="bold">
            อัพโหลดหลักฐานการชำระเงิน
          </Typography>{' '}
          ด้านล่าง <br />
          ทั้งนี้ผู้เรียนสามารถเข้าชมเนื้อหาคอร์สได้ทันทีหลังการอนุมัติภายใน 24
          ชั่วโมง
        </Typography>
        <Box display="flex">
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Receipt></Receipt>}
            onClick={openFileDialog}
          >
            อัพโหลดหลักฐานการชำระเงิน
          </Button>
          {uploadFile && (
            <>
              <Button variant="text" color="inherit" sx={{ ml: 2 }}>
                {uploadFile.name}
              </Button>
              <IconButton onClick={deleteFileUpload}>
                <Delete></Delete>
              </IconButton>
            </>
          )}
        </Box>
        <Box visibility="hidden">
          <input
            type="file"
            accept={UPLOAD_SLIP_SUPPORTED_FORMATS}
            ref={uploadSlipRef}
            onChange={handleFileUpload}
          />
        </Box>
        <Typography variant="h6" fontWeight="bold">
          ข้อตกลงการลงทะเบียนและใช้งานคอร์ส
        </Typography>
        <Box
          sx={{
            my: 2,
            p: 2,
            height: 250,
            overflow: 'auto',
            border: '1px solid grey',
          }}
        >
          {
            <>
              <ul>
                {terms.map((term, i) => (
                  <li key={i}>{term}</li>
                ))}
                <li>
                  คอร์สออนไลน์นี้สามารถเข้าชมได้ตลอดเวลาจนกว่าเว็บจะยุติการให้บริการ
                </li>
                <li>
                  การสั่งซื้อสินค้าและบริการของเราเป็นไปตาม{' '}
                  <MuiLink component={Link} color="inherit" to="/policy">
                    ข้อตกลงการใช้งาน
                  </MuiLink>
                </li>
                <li>
                  โปรดอ่านรายละเอียดของคอร์สก่อนการลงทะเบียน
                  หากคุณได้สั่งซื้อคอร์สแล้วเราจะไม่สามารถดำเนินการคืนเงินหรือปรับเปลี่ยนการสั่งซื้อได้ในทุกกรณี
                </li>
              </ul>
              <Typography fontWeight="bold">
                เงื่อนไขเพิ่มเติม: กรณีสั่งซื้อในนามองค์กร/หน่วยงาน
              </Typography>
              <ul>
                <li>
                  สามารถติดต่อเพื่อขอใบเสนอราคา/ใบแจ้งหนี้/ใบเสร็จรับเงิน
                  ได้ผ่านทางเฟสบุ๊ค โค้ดศาสตร์ หรือ codesass@hotmail.com
                </li>
                <li>
                  การสั่งซื้อในนามบริษัท/องค์กร/หน่วยงาน
                  ระยะเวลาการเข้าชมคอร์สจะเป็น 1 ปี
                </li>
                <li>
                  หากมีการหักภาษี ณ ที่จ่าย
                  ผู้ลงทะเบียนจะสามารถเข้าชมคอร์สได้ภายหลังที่ทางเพจได้รับเอกสารรับรองการหักภาษี
                  ณ ที่จ่าย (ตัวจริง) แล้วเท่านั้น
                </li>
              </ul>
            </>
          }
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={acceptTerms} onChange={handleAcceptTerms} />
            }
            label="ข้าพเจ้ายอมรับข้อตกลงการลงทะเบียนและใช้งานคอร์ส"
          />
        </FormGroup>
        <Button
          variant="contained"
          sx={{ mx: 'auto' }}
          disabled={!acceptTerms || !uploadFile}
          onClick={register}
        >
          ลงทะเบียน
        </Button>
      </Container>
      <Dialog
        aria-labelledby="upload-file-error-dialog-title"
        aria-describedby="upload-file-error-dialog-description"
        open={Boolean(uploadFileError)}
        onClose={clearUploadFileError}
      >
        <DialogTitle id="upload-file-error-dialog-title">
          เกิดข้อผิดพลาดในการอัพโหลดไฟล์
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{uploadFileError}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={clearUploadFileError}>
            รับทราบ
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={registered}
        onClose={navigateToCoursePage}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          การลงทะเบียนเสร็จสมบูรณ์
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            ขอบคุณสำหรับการลงทะเบียนคอร์ส{' '}
            <Typography variant="subtitle1" component="span" fontWeight="bold">
              {title}
            </Typography>
            <br />
            เราจะใช้เวลาตรวจสอบข้อมูลภายใน 24 ชั่วโมง <br />
            เมื่อได้รับการอนุมัติคุณจะสามารถเข้าชมคอร์สได้ทันที <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={navigateToCoursePage}>
            รับทราบ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Register;
