import { courses as types } from '@codesass/types';
import { graphql, PageProps } from 'gatsby';

import EmailVerifiedRequiredGuard from 'modules/auth/components/EmailVerifiedRequiredGuard';

import Register from 'modules/courses/components/Register';

type RegisterPageProps = {
  coursesYaml: Pick<types.Course, 'title' | 'price' | 'promotion' | 'terms'> & {
    fields: {
      slug: types.Course['slug'];
    };
  };
};

const RegisterPage = ({
  data: {
    coursesYaml: {
      title,
      price,
      promotion,
      terms,
      fields: { slug },
    },
  },
}: PageProps<RegisterPageProps>) => {
  return (
    <EmailVerifiedRequiredGuard>
      <Register
        slug={slug}
        title={title}
        price={price}
        promotion={promotion}
        terms={terms}
      ></Register>
    </EmailVerifiedRequiredGuard>
  );
};

export const query = graphql`
  query CourseRegisterQuery($id: String) {
    coursesYaml(id: { eq: $id }) {
      fields {
        slug
      }
      price
      promotion
      title
      terms
    }
  }
`;

export default RegisterPage;
