import { Box, CircularProgress } from '@mui/material';

const ProgressIndicator = () => {
  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      sx={{ transform: 'translate(-50%. -50%)' }}
    >
      <CircularProgress></CircularProgress>
    </Box>
  );
};

export default ProgressIndicator;
